import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/home/index'
import Dxtintx from './pages/dxtintx/index'
import BanWords from './pages/dxtintx.twitch-n-words-text-filter/index'
import Agent from './pages/agent-inspector/index'
import Fzf from './pages/404/index'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="dxtintx" element={<Dxtintx />} />
          <Route path="agent-inspector" element={<Agent />} />
          <Route path="dxtintx/twitch-n-words-text-definer" element={<BanWords />} />
          <Route
            path="/*"
            element={<Fzf />}
          />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);