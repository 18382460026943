import React, { useEffect } from 'react'
import platform from 'platform'

function Screen() {
    useEffect(() => {
        const screen = [
            "availHeight = " + window.screen.availHeight,
            "availWidth = " + window.screen.availWidth,
            "colorDepth = " + window.screen.colorDepth,
            "pixelDepth = " + window.screen.pixelDepth,
            "height = " + window.screen.height,
            "width = " + window.screen.width,
            "angle = " + window.screen.orientation.angle,
            "type = " + window.screen.orientation.type,
            "innerHeight = " + window.innerHeight,
            "innerWidth = " + window.innerWidth,
            "clientHeight = " + document.documentElement.clientHeight,
            "clientWidth = " + document.documentElement.clientWidth
        ]
        const device = [
            "agent = " + navigator.userAgent, 
            "agentName = " + platform.name,
            "agentVersion = " + platform.version,
            "agentProduct = " + platform.product,
            "agentManufacturer = " + platform.manufacturer,
            "agentLayout = " + platform.layout,
            "agentOs = " + platform.os,
            "agentDescription = " + platform.description,
        ]
        let r = "";
        const s = [...screen, ...device]
        s.forEach(s => {
            r += s + "\n"
        });
        (document.querySelector("pre") as HTMLPreElement).innerHTML = r
    })
    return <pre style={{
        margin: "1%",
        fontFamily: "monospace",
    }}></pre>
}

export default Screen;