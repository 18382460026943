/* eslint-disable no-sequences */
import React, { useEffect } from 'react'
import style from './index.module.css';
import './keyframes.css'
import rsvg from './react.svg';
import logo from '../home/icon.png'

function Fzf() {
    useEffect(() => {
      var titleEl = document.querySelector("title") as HTMLTitleElement;
      var iconEl = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      iconEl.setAttribute("href", logo)
      document.querySelector('head')?.insertBefore(iconEl, titleEl);
      titleEl.textContent = "Not Found";
      function drawVisor() {
          const canvas = document.getElementById('visor') as HTMLCanvasElement;
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
          
          ctx.beginPath();
          ctx.moveTo(5, 45);
          ctx.bezierCurveTo(15, 64, 45, 64, 55, 45);
          
          ctx.lineTo(55, 20);
          ctx.bezierCurveTo(55, 15, 50, 10, 45, 10);
          
          ctx.lineTo(15, 10);
          
          ctx.bezierCurveTo(15, 10, 5, 10, 5, 20);
          ctx.lineTo(5, 45);
          
          ctx.fillStyle = '#2f3640';
          ctx.strokeStyle = '#f5f6fa';
          ctx.fill();
          ctx.stroke();
      }
      
      const cordCanvas = document.getElementById('cord') as HTMLCanvasElement;
      const ctx = cordCanvas.getContext('2d') as CanvasRenderingContext2D;
      
      let y1 = 160;
      let y2 = 100;
      let y3 = 100;
      
      let y1Forward = true;
      let y2Forward = false;
      let y3Forward = true;
      
      const {innerHeight, innerWidth} = window;
      function animate() {
        requestAnimationFrame(animate);
        ctx.clearRect(0, 0, innerWidth, innerHeight);
        
        ctx.beginPath();
        ctx.moveTo(130, 170);
        ctx.bezierCurveTo(250, y1, 345, y2, 400, y3);
        
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 8;
        ctx.stroke();
       
        
        if (y1 === 100) {
          y1Forward = true;
        }
        
        if (y1 === 300) {
          y1Forward = false;
        }
        
        if (y2 === 100) {
          y2Forward = true;
        }
        
        if (y2 === 310) {
          y2Forward = false;
        }
        
        if (y3 === 100) {
          y3Forward = true;
        }
        
        if (y3 === 317) {
          y3Forward = false;
        }
        
        y1Forward ? y1 += 1 : y1 -= 1;
        y2Forward ? y2 += 1 : y2 -= 1;
        y3Forward ? y3 += 1 : y3 -= 1;
      }
      
      drawVisor();
      animate();
    })

    return <>
    <img className={style.moon} style={{
      borderRadius: 0,
      boxShadow: 'none',
      background: 'transparent',
      animation: "reactRotate 120s linear infinite"
    }} src={rsvg} alt="" />

    <div className={`${style.star, style.star1}`}></div>
    <div className={`${style.star, style.star2}`}></div>
    <div className={`${style.star, style.star3}`}></div>
    <div className={`${style.star, style.star4}`}></div>
    <div className={`${style.star, style.star5}`}></div>

    <div className={style.error}>
        <div className={style.error__title}>404</div>
        <div className={style.error__subtitle}>Hmmm...</div>
        <div className={style.error__description}>It looks like one of the  developers fell asleep</div>
        <button style={{display: "none"}} className={`${style.error__button}, ${style["error__button--active"]}`}>LOGIN</button>
        <button onClick={() => {window.location.href = "/"}} className={style.error__button}>HOME</button>
    </div>

    <div className={style.astronaut} style={{animation: "astronaut 15s infinite normal"}}>
        <div className={style["astronaut__backpack"]}></div>
        <div className={style["astronaut__body"]}></div>
        <div className={style["astronaut__body__chest"]}></div>
        <div className={style["astronaut__arm-left1"]}></div>
        <div className={style["astronaut__arm-left2"]}></div>
        <div className={style["astronaut__arm-right1"]}></div>
        <div className={style["astronaut__arm-right2"]}></div>
        <div className={style["astronaut__arm-thumb-left"]}></div>
        <div className={style["astronaut__arm-thumb-right"]}></div>
        <div className={style["astronaut__leg-left"]}></div>
        <div className={style["astronaut__leg-right"]}></div>
        <div className={style["astronaut__foot-left"]}></div>
        <div className={style["astronaut__foot-right"]}></div>
        <div className={style["astronaut__wrist-left"]}></div>
        <div className={style["astronaut__wrist-right"]}></div>
    
        <div className={style.astronaut__cord}>
            <canvas id="cord" height="500px" width="500px"></canvas>
        </div>
    
        <div className={style.astronaut__head}>
            <canvas id="visor" width="60px" height="60px"></canvas>
            <div className={style["astronaut__head-visor-flare1"]}></div>
            <div className={style["astronaut__head-visor-flare2"]}></div>
        </div>
    </div></>
}
export default Fzf