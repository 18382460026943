import style from './index.module.css'
import './keyframes.css'
import React, {useEffect} from 'react'
import icon from './icon.svg'
import logo from './icon.png'

function Home() {
	
	useEffect(() => {
		document.onload = () => {
			(document.querySelector("div#background > video") as HTMLVideoElement).play()
		}
		setTimeout(() => {
			(document.querySelector("div#head") as HTMLDivElement).setAttribute("style", "animation: fadeupLogo2 1s .3s normal both !important")
		}, 1000)
		setTimeout(() => {
			document.querySelectorAll("div#body div#inner div#container, div#body div#inner div#container div#item, div#body div#inner span").forEach(e => {
				e.setAttribute("style", "filter: opacity(1) !important")
			})
		}, 3200)
		var titleEl = document.querySelector("title") as HTMLTitleElement;
    	var iconEl = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    	iconEl.setAttribute("href", logo)
    	document.querySelector('head')?.insertBefore(iconEl, titleEl);
    	titleEl.textContent = "Twelve Nord";
	}, [])

    return (
        <>
			<div className={style.background}>
				<video autoPlay muted loop style={{
					position: "absolute",
					top: 0,	
					left: 0,
					width: '140%',
					height: '172%'
				}}><source src="https://cerber.io/video/bg-video-cerber.mp4" /></video>
			</div>
			<div className={style.muter}></div>
			<div className={style.content} id="content">
				<div className={style.head} id="head">
					<img src={icon} alt="" />
				</div>
				<div className={style.body} id="body">
					<div className={style.inner} id="inner">
						<span>Наши проекты</span>
						<br />
						<div className={style.container} id="container">
							<div className={style.item} id="item" key={0} onClick={() => {window.location.href = 'https://discord.gg/ZmbtfE8svq'}}>
								<div className={style.title}>Cerber</div>
								<div className={style.description}>Minecraft: Java Edition<br />Нажмите, чтобы присоединиться к Discord серверу.</div>
							</div>
							<div className={style.item} id="item" key={1}>Coming soon</div>
							<div className={style.item} id="item" key={2}>Coming soon</div>
							<div className={style.item} id="item" key={3}>Coming soon</div>
						</div>
					</div>
				</div>
			</div>
		</>
    )
}
export default Home