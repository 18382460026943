import React, { useEffect, useState } from 'react';
import LoaderModuleStyle from './loader.module.css';

function Loader() {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(false)
    }, [])
    if (isLoading) {
        return (
            <div className={LoaderModuleStyle.loader}>
                <span className={LoaderModuleStyle.loader}></span>
            </div>
        )
    }
    return (<span className={LoaderModuleStyle._hidden}></span>)
}

export default Loader;