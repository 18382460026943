import SkillsSectionModuleStyle from './skills-section.module.css'
import '../index.module.css'

function SkillsSection() {
    return (
        <div id="skills" className={SkillsSectionModuleStyle.skills_section}>
            <div className={SkillsSectionModuleStyle.title}>
                <h1 className={SkillsSectionModuleStyle.title}>
                    Skills
                </h1>
            </div>
            <div className={SkillsSectionModuleStyle.container}>
                <div className={SkillsSectionModuleStyle.box}>
                    <h2>JavaScript</h2>
                </div>
                <div className={SkillsSectionModuleStyle.box}>
                    <h2>TypeScript</h2>
                </div>
            </div>
            
        </div>
    )
}

export default SkillsSection;