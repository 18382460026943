import React from "react";
import { Form, Button, Alert } from 'react-bootstrap';
import AppModuleStyle from './App.module.css';
import bwl from './assets/banWords.json'

function App() {
    function define() {

        var el: HTMLTextAreaElement = document.querySelector("textarea.form-control") as HTMLTextAreaElement;
        var text = el.value;
        var warned: Boolean = false;
        var nwords: any[] = []
        bwl.forEach((w: string) => {
            if (text.includes(w)) {
                warned = true;
                nwords.push(w)
            }
        })
        let resultEl: HTMLDivElement = document.querySelector("div.result > [role='alert']") as HTMLDivElement;
        if (warned) {
            resultEl.classList.remove("alert-info");
            resultEl.classList.remove("alert-success");
            resultEl.classList.add("alert-danger");
            resultEl.innerText = "Banned words found: | " + nwords.join(" | ") + ' |';
        } else {
            resultEl.classList.remove("alert-info");
            resultEl.classList.remove("alert-danger");
            resultEl.classList.add("alert-success");
            resultEl.innerText = "No banned words found";
        }
    }
    return (<Form className={AppModuleStyle.twitch_ban_word_definer}>
        <Form.Group className={AppModuleStyle.result} controlId="filterForm.ControlOutput1">
            <Alert className={AppModuleStyle.alert_info} variant={"info"}>
              System detects ban words in English, Dutch, Korean, Japanese, Italian, French, Finnish, Spanish, German, Czech and Norwegian languages.
            </Alert>
        </Form.Group>
        <Form.Group controlId="filterForm.ControlTextarea1">
          <Form.Label className={AppModuleStyle.label}>Text to filter</Form.Label>
          <Form.Control as="textarea" rows={9} />
        </Form.Group>
        <Form.Group controlId="filterForm.ControlInput1">
          <Button onClick={define}>Start</Button>
          <Button onClick={() => {window.location.href = "https://discord.com/users/445565647653699594"}}>Submit a report</Button>
        </Form.Group>
      </Form>)
}

export default App;