import React from 'react';
import AppModuleStyle from './App.module.css';
import Header from './components/header'
import WelcomeSection from './components/welcome-section'
import Loader from './components/loader'
import SkillsSection from './components/skills-section'

function App() {
  return (
    <div className={AppModuleStyle.Dxtintx}>
        <Loader />
        <Header />
        <WelcomeSection />
        <SkillsSection />
        <div id={AppModuleStyle.anchor}>1</div>

    </div>
  );
}

export default App;
